<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">{{ $t(`wysiwygModal.edit`, {name: name}) }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`orderChange.close`)"
            v-on:click="closeModal()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row row-tabs">
            <div class="col-auto p-0 m-0" v-for="l in languages" :key="l.code">
              <button
                class="btn btn-tab"
                :class="{ active: l.code === selectedLanguage }"
                v-on:click="selectedLanguage = l.code"
              >
                <span class="semibold-14">{{ l.name }}</span>
              </button>
            </div>
          </div>
          <div class="mt-4" v-if="show && modelValueData !== undefined">
            <div
              v-for="l in languages"
              :key="l.code"
              v-show="selectedLanguage === l.code"
            >
              <WYSIWYG v-model="modelValueData[l.code]" v-on:change="change" />
            </div>
          </div>
        </div>
        <div class="modal-footer custom-input">
          <div v-for="banner in banners" :key="banner">
            <span v-html="banner.text" v-if="banner.isShow"></span>
            <span v-if="banner.buttons !== null && banner.isShow">
              <button
                v-for="button in banner.buttons"
                :key="button"
                v-on:click="button.onClick()"
                class="btn"
                :class="button.type"
              >
                {{ button.name }}
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WYSIWYG from "./WYSIWYG";
import { useStore } from "vuex";
import http from "../../modules/http";
import { Modal } from "@/modules/apps/modal";
import { Banner } from "@/modules/apps/banner";

const bootstrap = require("bootstrap");

export default {
  name: "WysiwygModal",
  props: {
    show: Boolean,
    name: String,
    modelValue: String,
    ajaxUrl: String,
    id: Number,
    extraRow: Number,
  },
  emits: ["update:modelValue", "update:show", "change", "update:extraRow"],
  data: function () {
    return {
      modelValueData: this.modelValue,
      selectedLanguage: "en",
      needSave: false,
      saveTime: null,
      appModal: null,
      banners: [],
    };
  },
  mounted() {
    let myModal = new bootstrap.Modal(this.$refs.modal, {
      backdrop: "static",
    });
    if (this.show) {
      myModal.show();
    } else {
      myModal.hide();
    }

    if (this.modelValueData === undefined && this.ajaxUrl !== undefined) {
      http.fetch(this.ajaxUrl + "?id=" + this.id).then((data) => {
        this.modelValueData = data;
        this.needSave = false;

        this.appModal = new Modal();
        this.appModal.subscribe("open", () => myModal.show());
        this.appModal.subscribe("close", () => this.closeModal());
        this.appModal.subscribe("addBanner", (text, buttons) =>
          this.addBanner(text, buttons)
        );

        // need to change (always products at this moment)
        window.apps.callEvent(
          "products:modalOpen",
          this.appModal,
          "descriptions",
          this.id,
          this.modelValueData
        );
      });
    }
  },
  computed: {
    languages() {
      const store = useStore();
      return store.state.languages;
    },
  },
  watch: {
    show() {
      let myModal = new bootstrap.Modal(this.$refs.modal, {
        backdrop: "static",
      });
      if (this.show) {
        myModal.show();
      } else {
        myModal.hide();
      }
    },
  },
  methods: {
    addBanner(text, buttons) {
      const banner = new Banner(text, buttons);
      this.banners.push(banner);
      return banner;
    },
    change() {
      this.needSave = true;
      if (this.saveTime === null) {
        this.saveTime = setTimeout(this.save, 2000);
      }
    },
    closeModal() {
      this.$emit("update:show", false);
      this.save();
    },
    save() {
      if (this.needSave) {
        this.$emit("update:modelValue", this.modelValueData);
        this.$emit("change", this.modelValueData);
        this.needSave = false;

        if (Object.keys(this.modelValueData).length === this.languages.length) {
          this.$emit("update:extraRow", 1);
        } else if (Object.keys(this.modelValueData).length > 0) {
          this.$emit("update:extraRow", 2);
        }

        if (this.saveTime !== null) {
          clearInterval(this.saveTime);
          this.saveTime = null;
        }
      }
    },
  },
  components: { WYSIWYG },
};
</script>
