export class Modal {
  constructor() {
    this.events = {};
  }

  open() {
    this.callEvent("open");
  }

  close() {
    this.callEvent("close");
  }

  addBanner(text, buttons = null) {
    return this.callEvent("addBanner", text, buttons);
  }

  callEvent(event, ...data) {
    if (this.events[event] === undefined) {
      return;
    }
    let method = this.events[event];
    return method(...data);
  }

  subscribe(event, method) {
    this.events[event] = method;
  }
}
