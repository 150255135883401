export class Banner {
  constructor(text, buttons) {
    this.text = text;
    this.buttons = buttons;
    this.isShow = false;
  }

  show() {
    this.isShow = true;
  }

  hide() {
    this.isShow = false;
  }

  addBanner(text, buttons = null) {
    this.callEvent("addBanner", text, buttons);
  }

  callEvent(event, ...data) {
    if (this.events[event] === undefined) {
      return;
    }
    for (const method of this.events[event]) {
      method(...data);
    }
  }

  subscribe(event, method) {
    if (this.events[event] === undefined) {
      this.events[event] = [];
    }
    this.events[event].push(method);
  }
}
